/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-align: inherit;
  background: transparent;
  cursor: pointer;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none; }
  button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  button :focus:not(:focus-visible) {
    outline: none; }
  button :focus:not(:-moz-focusring) {
    outline: none; }

input :focus:not(:focus-visible) {
  outline: none; }

input :focus:not(:-moz-focusring) {
  outline: none; }

label {
  cursor: inherit; }

*:not(h2) + form {
  margin-top: 2em; }

:root {
  --color-foreground: #242323;
  --color-background: #efefe9;
  --color-accent: #dae4ea;
  --color-accent--dark: #7abae0;
  --outline--dotted: dotted 1px var(--color-foreground);
  --outline--text: 0.75px var(--color-foreground);
  --font-size--secondary: 0.72rem;
  --col-right: 26%;
  --col-left: calc(100% - var(--col-right));
  --bar-height: 1.42rem; }

html {
  font-size: 150%;
  scroll-behavior: smooth; }
  @media (min-width: 680px) {
    html {
      font-size: 1.45vw; } }

body {
  position: relative;
  margin: 0;
  font-family: 'Arial', sans-serif;
  line-height: 1.2;
  color: var(--color-foreground);
  background: var(--color-background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

::selection {
  background: #faf6ca; }

.section__heading,
.page h2,
.page h3 {
  font-size: var(--font-size--secondary);
  margin-bottom: 1rem;
  text-transform: uppercase; }

p + p,
.para-spacing + .para-spacing,
p + * {
  margin-top: 1rem; }

a {
  color: inherit;
  background: inherit;
  text-decoration-style: dotted;
  text-decoration-thickness: 1px;
  position: relative; }
  a:hover {
    opacity: 0.8; }

em {
  font-style: italic; }

img {
  width: 100%;
  height: auto; }

.text-outline {
  -webkit-text-stroke: var(--outline--text);
  color: transparent;
  background: transparent; }

.issues__title {
  -webkit-text-stroke: var(--outline--text);
  color: transparent;
  background: transparent; }

*:not(.issues__item):target {
  margin-top: calc((4rem + 20vh) * -1);
  padding-top: calc(4rem + 20vh); }

.size-modify {
  font-size: 85%; }

ul:not([class]):not([id]) > li:not([class]):not([id]) {
  list-style-type: circle;
  margin-left: 1em; }
  ul:not([class]):not([id]) > li:not([class]):not([id]):last-child {
    margin-bottom: 1em; }

.screen-reader,
.hidden {
  display: none; }

@media (min-width: 680px) {
  .content-mobile {
    display: none; } }

.content-desktop {
  display: none; }
  @media (min-width: 680px) {
    .content-desktop {
      display: block; } }

header {
  position: fixed;
  top: 0.2em;
  right: 0.65em;
  z-index: 100; }
  header h1 {
    font-size: 250%;
    line-height: 1.1; }
    @media (min-width: 680px) {
      header h1 {
        font-size: 370%; } }
    header h1 a {
      text-decoration: none; }
      header h1 a:hover {
        opacity: inherit; }

@media (min-width: 680px) {
  main {
    position: relative;
    right: 0;
    width: var(--col-right);
    margin-left: auto; } }

#about {
  padding-top: calc(clamp(5rem, 13.2rem, 50vh)); }

section {
  position: relative; }

.section {
  overflow: hidden;
  background: var(--color-background); }
  .section:not(.scroll) {
    padding: 2.5rem 0.85em;
    border-bottom: var(--outline--dotted);
    border-left: var(--outline--dotted);
    position: relative; }

@media (min-width: 680px) {
  .scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    -ms-overflow-style: none;
    scrollbar-width: auto;
    scrollbar-color: rgba(0, 0, 0, 0.15) transparent !important;
    height: 100%;
    max-height: 100%;
    scroll-behavior: smooth; } }

#contact .button--round {
  margin-top: 0.85em;
  margin-left: auto;
  margin-right: auto; }

#issues {
  z-index: 20; }
  #issues .spacer {
    height: 3.4rem;
    visibility: hidden; }
  @media (min-width: 680px) {
    #issues {
      position: fixed;
      top: 0;
      left: 0;
      width: var(--col-left);
      overflow-y: scroll;
      margin-left: 0;
      margin-right: auto;
      height: calc(100% - (var(--bar-height) * var(--bar-amount))); } }

.issues__item {
  position: relative;
  z-index: 10;
  -webkit-transform: translateZ(0); }
  .issues__item .issues__wrapper {
    display: grid;
    grid-row-gap: 1rem;
    align-content: start;
    min-height: calc(100vh - (var(--bar-height) * var(--bar-amount)));
    padding: 2.4rem 0.85rem 3.4rem;
    background: var(--color-background);
    position: relative;
    box-sizing: border-box;
    background: linear-gradient(180deg, #d7d7d2 0%, #c3c3be 10px, #c3c3be 15px, #e1e1db 35px, #efefe9 50px, #efefe9 calc(100% - 32px), #d7d7d5 100%); }
    .issues__item .issues__wrapper .issues__meta {
      font-size: var(--font-size--secondary); }
      .issues__item .issues__wrapper .issues__meta > * {
        display: block; }
    .issues__item .issues__wrapper .issues__title {
      font-size: 115%; }
      .issues__item .issues__wrapper .issues__title a {
        text-decoration: none; }
        .issues__item .issues__wrapper .issues__title a:hover {
          opacity: inherit; }
    .issues__item .issues__wrapper .issues__contributors {
      margin-bottom: 1.4em; }
      .issues__item .issues__wrapper .issues__contributors ul {
        margin-bottom: 2.4em; }
    .issues__item .issues__wrapper .issues__content-right {
      margin-bottom: 3.8em; }
    .issues__item .issues__wrapper .issues__buttons > * + * {
      margin-top: 0.6em; }
    @media (min-width: 680px) {
      .issues__item .issues__wrapper {
        grid-template-columns: 1fr 0.8fr;
        grid-template-rows: auto auto auto 1fr;
        grid-column-gap: 10%;
        padding-left: 1.4rem;
        padding-right: 1.4rem; }
        .issues__item .issues__wrapper h3,
        .issues__item .issues__wrapper h4 {
          min-height: 1.38rem; }
        .issues__item .issues__wrapper .issues__meta {
          grid-column: 1 / -1; }
        .issues__item .issues__wrapper .issues__title {
          min-height: 1.2rem; }
        .issues__item .issues__wrapper .issues__content {
          grid-row: 3 / span 2; }
        .issues__item .issues__wrapper .issues__contributors {
          grid-column: 2 / span 1;
          grid-row: 2 / span 2; }
        .issues__item .issues__wrapper .issues__content-right {
          grid-column: 2 / span 1;
          grid-row: 3 / span 1; }
        .issues__item .issues__wrapper .issues__buttons {
          grid-column: 2 / span 1;
          grid-row: 4 / span 1; } }
  .issues__item.sticky {
    position: sticky;
    z-index: 0; }
  .issues__item:not(.sticky) {
    top: 0 !important; }

#bars {
  font-size: var(--font-size--secondary);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: inherit;
  background: var(--color-accent);
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  z-index: 30; }
  #bars .bar {
    background: var(--color-accent);
    display: flex;
    height: var(--bar-height);
    align-items: center; }
    #bars .bar span {
      position: relative;
      transform: translateX(-0.6em);
      padding-left: 1.46em;
      padding-right: 0.6em; }
      #bars .bar span::before {
        display: block;
        content: "";
        position: absolute;
        border: 1px solid var(--color-foreground);
        border-radius: 50%;
        width: 0.86em;
        height: 0.57em;
        left: 0;
        top: 0.24em; }
  @media (min-width: 680px) {
    #bars {
      width: var(--col-left); }
      #bars span {
        transform: translateX(-2.6em); } }

details {
  display: block;
  position: relative;
  outline: 0;
  padding-left: 1.4em; }
  details::-webkit-details-marker, details::marker {
    display: none; }
  details summary {
    display: flex;
    list-style: none;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 1px; }
    details summary > .issues__title {
      display: block;
      padding-bottom: 0.08em; }
    details summary::before {
      display: block;
      content: "";
      position: absolute;
      border: 1px solid var(--color-foreground);
      border-radius: 50%;
      width: 0.86em;
      height: 0.57em;
      left: 0;
      top: 0.28rem; }
    details summary::-webkit-details-marker {
      display: none; }
  details > ul > li > details > summary::before {
    font-size: 80%; }
  details .content {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transform-origin: top; }
    details .content li + li {
      margin-top: 1rem; }
  details[open] > summary::before,
  details summary:hover::before {
    background: var(--color-accent);
    filter: brightness(1.05); }
  details > summary::after {
    content: "";
    position: relative;
    top: 0.37em;
    left: 0.5em;
    width: 0;
    height: 0;
    border-top: 0.35em solid transparent;
    border-bottom: 0.35em solid transparent;
    border-right: 0.6em solid var(--color-foreground); }
  details[open] > summary::after {
    top: 0.42em;
    border-top: 0.6em solid var(--color-foreground);
    border-bottom: 0;
    border-left: 0.35em solid transparent;
    border-right: 0.35em solid transparent; }

.button--round {
  font-size: 85%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: min-content;
  height: min-content;
  padding: 0.62em 0.9em;
  border: var(--outline--dotted);
  border-radius: 5em;
  text-decoration: none; }
  .button--round:hover {
    opacity: unset;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 1px; }
  .button--round.disabled {
    pointer-events: none;
    opacity: 0.5; }

input {
  --margin: 0.7rem;
  display: block;
  color: var(--color-accent--dark);
  font-size: 1em;
  font-family: inherit;
  width: calc(100% - var(--margin) * 2);
  margin: 1.2rem var(--margin);
  padding: 0.15em;
  background: transparent;
  border: none;
  border-bottom: var(--outline--dotted);
  border-radius: 0; }
  input::placeholder, input::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.5; }

#contact .status {
  text-align: center; }
  #contact .status > * {
    display: none; }
  #contact .status.success > .success {
    display: block; }
  #contact .status.error > .error {
    display: block; }

.more-less:not(.more-less--active) .more-less__more {
  display: none; }

.more-less .more__inner {
  padding-top: 1em; }

.more-less .more-less__button span {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-thickness: 1px; }

.issues__images {
  --size: 10em;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10; }
  .issues__images li {
    position: absolute;
    width: var(--size);
    height: var(--size);
    top: max(calc(0 * var(--size)), calc(((100% - var(--size)) / 100) * var(--random-top)));
    left: max(calc(0 * var(--size)), calc(((100% - var(--size)) / 100) * var(--random-left)));
    pointer-events: none;
    transition-duration: 300ms;
    transition-delay: calc(var(--delay) * 0.7ms);
    transition-property: opacity, transform;
    display: flex;
    justify-content: center;
    align-items: center; }
    .issues__images li img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%; }
  .issues__images.issues__images--active img {
    pointer-events: auto; }
  .issues__images:not(.issues__images--active) {
    pointer-events: none; }
    .issues__images:not(.issues__images--active) li {
      opacity: 0;
      transform: scale(0.9, 0.9); }

.image-grid {
  --col-gap: 0.85em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: var(--col-gap);
  row-gap: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em; }
  .image-grid .image-grid__item {
    width: 6em;
    height: 3em; }
    .image-grid .image-grid__item img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center; }
  @media (min-width: 680px) {
    .image-grid {
      justify-content: space-between; }
      .image-grid .image-grid__item {
        width: calc(49% - var(--col-gap) / 2); } }

body:not(.painting-disabled) main,
body:not(.painting-disabled) .issues__wrapper {
  cursor: url("/assets/images/brush.svg") 16 16, auto; }

.painting-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  filter: blur(1px);
  cursor: url("/assets/images/brush.svg") 16 16, auto;
  touch-action: none;
  user-select: none;
  pointer-events: none;
  z-index: 10; }

.painting-palette {
  position: fixed;
  bottom: 0.75em;
  right: 0.55em;
  z-index: 100; }
  .painting-palette .painting-palette__button {
    position: relative;
    overflow: hidden;
    background: var(--color-background);
    width: 2em;
    height: 0;
    padding-bottom: 78%;
    border: var(--outline--dotted);
    border-radius: 50%; }
    .painting-palette .painting-palette__button::before {
      content: "";
      position: absolute;
      width: 100%;
      transition-duration: 400ms;
      transition-property: background-color; }
    .painting-palette .painting-palette__button:not(.disabled)::before {
      left: 0;
      top: 50%;
      border-top: 1px dashed black;
      transform: skewY(-20deg);
      z-index: 10;
      background-color: var(--color-background); }
    .painting-palette .painting-palette__button.disabled::before {
      top: 0;
      height: 100%;
      border-radius: 50%;
      background-color: var(--color-accent--dark);
      filter: blur(0.5em); }
