#issues {
  z-index: 20;

  .spacer {
    height: 3.4rem;
    visibility: hidden;
  }

  @include breakpoint(medium) {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--col-left);
    overflow-y: scroll;
    margin-left: 0;
    margin-right: auto;
    height: calc(100% - (var(--bar-height) * var(--bar-amount)));
  }
}

.issues__item {
  position: relative;
  z-index: 10;
  -webkit-transform: translateZ(0);

  .issues__wrapper {
    display: grid;
    grid-row-gap: 1rem;
    align-content: start;
    min-height: calc(100vh - (var(--bar-height) * var(--bar-amount)));
    padding: 2.4rem 0.85rem 3.4rem;
    background: var(--color-background);
    position: relative;
    box-sizing: border-box;
    background: linear-gradient(
      180deg,
      rgb(215,215,210) 0%,
      rgb(195,195,190) 10px,
      rgb(195,195,190) 15px,
      rgb(225,225,219) 35px,
      rgb(239,239,233) 50px,
      rgb(239,239,233) calc(100% - 32px),
      rgb(215,215,213) 100%
    );

    .issues__meta {
      font-size: var(--font-size--secondary);

      > * {
        display: block;
      }
    }

    .issues__title {
      font-size: 115%;

      a {
        text-decoration: none;

        &:hover {
          opacity: inherit;
        }
      }
    }

    .issues__contributors {
      margin-bottom: 1.4em;

      ul {
        margin-bottom: 2.4em;
      }
    }

    .issues__content-right {
      margin-bottom: 3.8em;
    }

    .issues__buttons {
      > * + * {
        margin-top: 0.6em;
      }
    }


    @include breakpoint(medium) {
      grid-template-columns: 1fr 0.8fr;
      grid-template-rows: auto auto auto 1fr;
      grid-column-gap: 10%;
      padding-left: 1.4rem;
      padding-right: 1.4rem;

      h3,
      h4 {
        min-height: 1.38rem;
      }

      .issues__meta {
        grid-column: 1 / -1;
      }

      .issues__title {
        min-height: 1.2rem;
      }

      .issues__content {
        grid-row: 3 / span 2;
      }

      .issues__contributors {
        grid-column: 2 / span 1;
        grid-row: 2 / span 2;
      }

      .issues__content-right {
        grid-column: 2 / span 1;
        grid-row: 3 / span 1;
      }

      .issues__buttons {
        grid-column: 2 / span 1;
        grid-row: 4 / span 1;
      }
    }
  }

  &.sticky {
    position: sticky;
    z-index: 0;
  }

  &:not(.sticky) {
    top: 0 !important;
  }
}
