.image-grid {
  --col-gap: 0.85em;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: var(--col-gap);
  row-gap: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;

  .image-grid__item {
    width: 6em;
    height: 3em;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  @include breakpoint(medium) {
    justify-content: space-between;

    .image-grid__item {
      width: calc(49% - var(--col-gap) / 2);
    }
  }
}
