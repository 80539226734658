main {
  @include breakpoint(medium) {
    position: relative;
    right: 0;
    width: var(--col-right);
    margin-left: auto;
  }
}

#about {
  padding-top: calc(clamp(5rem, 13.2rem, 50vh));
}

section {
  position: relative;
}

.section {
  overflow: hidden;
  background: var(--color-background);

  &:not(.scroll) {
    padding: 2.5rem 0.85em;
    border-bottom: var(--outline--dotted);
    border-left: var(--outline--dotted);
    position: relative;
  }
}

.scroll {
  @include breakpoint(medium) {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    -ms-overflow-style: none;
    scrollbar-width: auto;
    scrollbar-color: rgba(0, 0, 0, 0.15) transparent !important;
    height: 100%;
    max-height: 100%;
    scroll-behavior: smooth;
  }
}

#contact {
  .button--round {
    margin-top: 0.85em;
    margin-left: auto;
    margin-right: auto;
  }
}
