details {
  display: block;
  position: relative;
  outline: 0;
  padding-left: 1.4em;

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }

  summary {
    display: flex;
    list-style: none;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 1px;

    > .issues__title {
      display: block;
      padding-bottom: 0.08em;
    }

    &::before {
      display: block;
      content: "";
      position: absolute;
      border: 1px solid var(--color-foreground);
      border-radius: 50%;
      width: 0.86em;
      height: 0.57em;
      left: 0;
      top: 0.28rem;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  > ul > li > details {
    > summary {
      &::before {
        font-size: 80%;
      }
    }
  }

  .content {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transform-origin: top;

    li + li {
      margin-top: 1rem;
    }
  }

  &[open] > summary::before,
  summary:hover::before {
    background: var(--color-accent);
    filter: brightness(1.05);
  }

  & > summary::after {
    content:"";
    position: relative;
    top: 0.37em;
    left: 0.5em;
    width: 0;
    height: 0;
    border-top: 0.35em solid transparent;
    border-bottom: 0.35em solid transparent;
    border-right: 0.6em solid var(--color-foreground);
  }

  &[open] > summary::after {
    top: 0.42em;
    border-top: 0.6em solid var(--color-foreground);
    border-bottom: 0;
    border-left: 0.35em solid transparent;
    border-right: 0.35em solid transparent;
  }
}
